import { trackEvent } from '../tracking';
import { ArdoqId } from '@ardoq/api-types';
import { TrackingLocation } from '../types';

export const trackLoadedApplication = () =>
  trackEvent('Loaded application', {}, true);

export const trackOpenedWorkspace = (metadata: {
  resourceId?: ArdoqId;
  useCase?: string;
  from?: TrackingLocation;
}) => trackEvent('Opened workspace', metadata);
