import { scopeDiff$ } from './scopeDiff$';
import { ScopeDiffState } from 'scope/types';

let currentScopeDiff: ScopeDiffState = { scopeDiff: null, isLoading: false };

scopeDiff$.subscribe(
  (scopeDiff: ScopeDiffState) => (currentScopeDiff = scopeDiff)
);

export const isLoadingScope = () => currentScopeDiff.isLoading;

export const isInScopeDiffMode = () => Boolean(currentScopeDiff.scopeDiff);
