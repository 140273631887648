import { ArdoqId, LoadedState } from '@ardoq/api-types';
import { LoadedGraph } from '@ardoq/graph';

import { actionCreator } from '@ardoq/rxbeach';

import { TraversalRow } from 'components/AssetsBrowser/types';

export const traversalNamespace = 'traversals';

export const navigateToViewpointsOverview = actionCreator(
  '[viewpoint] NAVIGATE_TO_VIEWPOINTS_OVERVIEW'
);

export const deleteViewpoint = actionCreator<ArdoqId>(
  '[viewpoint] DELETE_VIEWPOINT'
);

export const editViewpoint = actionCreator<ArdoqId>(
  '[viewpoint] EDIT_VIEWPOINT'
);

export const renameViewpoint = actionCreator<TraversalRow>(
  '[viewpoint] RENAME_VIEWPOINT'
);

export const replaceScopeComponentIds = actionCreator<LoadedGraph>(
  '[viewpoint] REPLACE_SCOPE_COMPONENT_IDS'
);

export type ClearTraversalAndSearchStatePayload = {
  keepCurrentViewPointMode?: boolean;
};

export const enterViewpointMode = actionCreator(
  '[viewpoint] ENTER_VIEWPOINT_MODE'
);

export const rebuildTraversalAndSearchState = actionCreator<{
  loadedStates: LoadedState[];
}>('[viewpoint] REBUILD_VIEWPOINT_AND_SEARCH_STATE');

export const clearLoadedGraphState =
  actionCreator<ClearTraversalAndSearchStatePayload>(
    '[viewpoint] CLEAR_TRAVERSALS_AND_SEARCH_STATE'
  );
