/* eslint-disable camelcase */
import { isEdge } from '@ardoq/common-helpers';
import { logError, logWarn } from '@ardoq/logging';
import { APP_VERSION } from 'appConfig';

let initialized = false;
let identifyWhenInitialized;
const trackWhenLoaded = [];

export default {
  initialize: function (analyticsKey, supportUrl) {
    try {
      // Reuse main window instance of Intercom if it exists
      if (window.parent?.IS_MAIN_ARDOQ_APP && window.parent.Intercom) {
        window.Intercom = window.parent.Intercom;
        return;
      }

      if (window.opener?.IS_MAIN_ARDOQ_APP && window.opener.Intercom) {
        window.Intercom = window.opener.Intercom;
        return;
      }
    } catch (e) {
      logWarn(Error(`Could not reuse main window Intercom instance - ${e}`));
    }
    const intercomSettings = {
      app_id: analyticsKey,
      ardoq_version: APP_VERSION,
      api_base: supportUrl,
    };
    if (analyticsKey) {
      try {
        (function () {
          const w = window;
          const ic = w.Intercom;
          if (typeof ic === 'function') {
            ic('reattach_activator');
            ic('update', intercomSettings);
          } else {
            const d = document;
            const i = function () {
              i.c(arguments);
            };
            i.q = [];
            i.c = function (args) {
              i.q.push(args);
            };
            w.Intercom = i;

            function l() {
              const s = d.createElement('script');
              s.type = 'text/javascript';
              s.async = true;
              s.src = `https://widget.intercom.io/widget/${analyticsKey}`;
              var x = d.getElementsByTagName('script')[0];
              x.parentNode.insertBefore(s, x);
            }
            l();
          }
        })();
        window.Intercom('boot', intercomSettings);
        initialized = true;
        if (identifyWhenInitialized) {
          this.identify(identifyWhenInitialized);
        }
        trackWhenLoaded?.forEach(({ action, metadata }) => {
          this.track(action, metadata);
        });
      } catch (error) {
        logError(error, 'Faild to load Intercom');
      }
    }
  },
  identify: function (userDetails) {
    if (window.Intercom) {
      window.Intercom('update', userDetails);
    } else if (!initialized) {
      identifyWhenInitialized = userDetails;
    }
  },
  track: function (action, metadata = {}) {
    if (window.Intercom) {
      try {
        window.Intercom('trackEvent', action, {
          ...metadata,
        });
      } catch (error) {
        logError(error, `Failed to track event to Intercom`, { action });
      }
    } else {
      trackWhenLoaded.push({ action, metadata });
    }
  },
  /** @param {number} tourId */
  startIntercomTour: function (tourId) {
    // Intercom tours have some issues with Edge that result in Intercom "bubble"
    // messages being unclickable. This causes users to get stuck in tours.
    if (isEdge()) return;
    if (!window.Intercom) return;
    window.Intercom('startTour', tourId);
  },
};
