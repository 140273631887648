import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import { toggleIsMergeFlowActive } from 'components/DiffMergeTable/actions';
import { setStateProperty } from '@ardoq/common-helpers';

interface IsMergeFlowActiveState {
  isMergeFlowActive: boolean;
}
const defaultState: IsMergeFlowActiveState = { isMergeFlowActive: false };

const isMergeFlowActive$ = persistentReducedStream<IsMergeFlowActiveState>(
  'isMergeFlowActive$',
  defaultState,
  [reducer(toggleIsMergeFlowActive, setStateProperty('isMergeFlowActive'))]
);
export const isMergeFlowActive = () =>
  isMergeFlowActive$.state.isMergeFlowActive;
