import { ArdoqId, ViewIds } from '@ardoq/api-types';
import { actionCreator } from '@ardoq/rxbeach';
import { PaneLocation } from 'streams/views/mainContent/types';
import { ResizeDirection } from './types';

export type ResizeUpdate = {
  x: number;
  y: number;
  box: DOMRect;
  direction: ResizeDirection;
};

export const resizeStart = actionCreator<ResizeUpdate>(
  '[appLayout] RESIZE_START'
);

export const resizeUpdate = actionCreator<ResizeUpdate>(
  '[appLayout] RESIZE_UPDATE'
);

export const resizeEnd = actionCreator<ResizeUpdate>('[appLayout] RESIZE_END');

export const resizeCancel = actionCreator('[appLayout] RESIZE_CANCEL');

export const splitPane = actionCreator('[appLayout] SPLIT_PANE');

export const closePane = actionCreator<{ location: PaneLocation }>(
  '[appLayout] UNSPLIT_PANE'
);

export const showSelectViewsDialog = actionCreator<{ location: PaneLocation }>(
  '[appLayout] SHOW_SELECT_VIEWS_DIALOG'
);

export const requestSaveViews = actionCreator<{
  workspaceId: ArdoqId;
  views: ViewIds[];
}>('[appLayout] SAVE_VIEWS');

export const leftPaneCreated =
  actionCreator<React.RefObject<HTMLDivElement> | null>(
    '[appLayout] LEFT_PANE_CREATED'
  );

export const rightPaneCreated =
  actionCreator<React.RefObject<HTMLDivElement> | null>(
    '[appLayout] RIGHT_PANE_CREATED'
  );
