import { actionCreator } from '@ardoq/rxbeach';
import {
  PayloadSetMergeData,
  PayloadSetMergeState,
  PayloadSetMergeStep,
  PayloadToggleIsExpandedMainStep,
  PayloadToggleIsExpandedTableRow,
} from './types';

export const setMergeData = actionCreator<PayloadSetMergeData>(
  '[DiffMergeTable] SET_MERGE_DATA'
);

export const setMergeStep = actionCreator<PayloadSetMergeStep>(
  '[DiffMergeTable] SET_MERGE_STEP'
);

export const setMergeState = actionCreator<PayloadSetMergeState>(
  '[DiffMergeTable] SET_MERGE_STATE'
);

export const resetMergeState = actionCreator<number>(
  '[DiffMergeTable] RESET_MERGE_STATE'
);

export const toggleIsExpandedTableRow =
  actionCreator<PayloadToggleIsExpandedTableRow>(
    '[DiffMergeTable] TOGGLE_IS_EXPANDED_TABLE_ROW'
  );

export const toggleIsExpandMainStep =
  actionCreator<PayloadToggleIsExpandedMainStep>(
    '[DiffMergeTable] TOGGLE_IS_EXPANDED_MAIN_STEP'
  );

export const toggleIsMergeFlowActive = actionCreator<boolean>(
  '[DiffMergeTable] TOGGLE_IS_MERGE_FLOW_ACTIVE'
);
