import Backbone from 'backbone';
import { setApiUrl } from 'backboneExtensions';

/** __THE_DOUBLE_UNDERSCORE_VARIABLES__ are replaced by rsbuild at build time. */
declare const __APP_VERSION__: string;
declare const __FEATURE_FLAGS__: Record<string, boolean> | undefined;

setApiUrl(Backbone.Model, '');
setApiUrl(Backbone.Collection, '');

export const isPresentationMode = () => {
  return window.IS_PRESENTATION;
};

export const isDevelopmentMode = () => {
  return process.env.NODE_ENV !== 'production';
};

export const isAutomatedTestMode = ({ detectUnitTestsOnly = false } = {}) => {
  if (detectUnitTestsOnly) return process.env.NODE_ENV === 'test';
  return process.env.NODE_ENV === 'test' || window.Cypress;
};

/*
 * On-premise installations does not have "cloud-features" enabled. Examples of
 * cloud features: intercom, segment, request account delete...
 */
export const isOnPremise = () => window.IS_ON_PREM;

export const attributePrefix = '@';

export const APP_VERSION = isAutomatedTestMode({ detectUnitTestsOnly: true })
  ? 'test'
  : __APP_VERSION__;

window.FEATURE_FLAGS = isAutomatedTestMode({ detectUnitTestsOnly: true })
  ? undefined
  : __FEATURE_FLAGS__;

/**
 * Only exposed on window so developers can access it in the console, please use `APP_VERSION` in code
 */
window.ARDOQ_BUILD_VERSION = APP_VERSION;

export const RESOURCE_BASE_URL =
  isDevelopmentMode() || window.CDN_URL === ''
    ? window.location.origin
    : `${window.CDN_URL}/`;

export const getCDNLink = (relativeUrl: string) =>
  `${RESOURCE_BASE_URL}${relativeUrl}`;
