import { actionCreator } from '@ardoq/rxbeach';
import {
  PaneInterface,
  PaneLocation,
  ViewInterface,
} from 'streams/views/mainContent/types';
import { ViewIds } from '@ardoq/api-types';

export interface PayloadActiveTab {
  readonly activeTabId: ViewIds;
}
export const setActiveMainTabLeft = actionCreator<PayloadActiveTab>(
  '[mainContent] SET_ACTIVE_MAIN_TAB_LEFT'
);
export const setActiveMainTabRight = actionCreator<PayloadActiveTab>(
  '[mainContent] SET_ACTIVE_MAIN_TAB_RIGHT'
);

export interface PayloadContainerCreated {
  readonly containerElement: HTMLElement | null;
}

export const rightContentContainerCreated =
  actionCreator<PayloadContainerCreated>(
    '[mainContent] RIGHT_CONTENT_CONTAINER_CREATED'
  );

export const renderMainContentView = actionCreator(
  '[mainContent] RENDER_MAIN_CONTENT_VIEW'
);

interface PayloadPaneCreated {
  readonly location: PaneLocation;
  readonly mainPane: PaneInterface | null;
}
export const mainPaneCreated = actionCreator<PayloadPaneCreated>(
  '[mainContent] MAIN_PANE_CREATED'
);

export interface PayloadViewChanged {
  readonly location: PaneLocation;
  readonly viewId: ViewIds;
  readonly view: ViewInterface;
}

export const viewChanged = actionCreator<PayloadViewChanged>(
  '[mainContent] VIEW_CHANGED'
);
export const viewLoaded = actionCreator<PayloadViewChanged>(
  '[mainContent] VIEW_LOADED'
);

export const viewChangeError = actionCreator('[mainContent] VIEW_CHANGE_ERROR');

type AllWorkspacesClosedPayload = {
  keepCurrentViewPointMode?: boolean;
};

export const allWorkspacesClosed = actionCreator<AllWorkspacesClosedPayload>(
  '[mainContent] ALL_WORKSPACES_CLOSED'
);
