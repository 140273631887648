import { actionCreator } from '@ardoq/rxbeach';
import {
  APIPresentationAttributes,
  ArdoqId,
  EnhancedPresentation,
} from '@ardoq/api-types';
import { SlidesMigrationInfo } from 'viewDeprecation/types';

export type LoadPresentationPayload = {
  presentationId: ArdoqId;
  organizationLabel: string | null;
  selectedSlideId: string | null;
  isCustomDomain: boolean;
};

export const loadPresentation = actionCreator<LoadPresentationPayload>(
  '[presentation] LOAD_PRESENTATION'
);

export const loadPresentationSuccess = actionCreator<EnhancedPresentation>(
  '[presentation] LOAD_PRESENTATION_SUCCESS'
);
export const loadPresentationError = actionCreator<{
  error: any;
}>('[presentation] LOAD_PRESENTATION_ERROR');
export const toggleExploreMode = actionCreator<{ isExploreMode: boolean }>(
  '[presentation] TOGGLE_EXPLORE_MODE'
);

export const renderMainPresentationView = actionCreator(
  '[mainContent] RENDER_MAIN_PRESENTATION_VIEW'
);

export const setSlidesMigrationInfo = actionCreator<SlidesMigrationInfo>(
  '[presentation] SET_SLIDE_MIGRATION_INFO'
);

export const enhancePresentationData = actionCreator<APIPresentationAttributes>(
  '[presentation] ENHANCE_PRESENTATION_DATA'
);

export const loadSurroundingPresentationData =
  actionCreator<EnhancedPresentation>(
    '[presentation] LOAD_SURROUNDING_PRESENTATION_DATA'
  );

export const loadLucidState = actionCreator('[presentation] LOAD_LUCID_STATE');
