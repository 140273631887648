import {
  Feature,
  Features,
  type PersistedFeatures,
  getLocalFeatureFlagOverrides,
} from '@ardoq/features';
import { isPresentationMode } from 'appConfig';
import currentUser from 'models/currentUser';
import { loadedGraph$ } from 'traversals/loadedGraph$';

const hasFeatureImpl = (feature: Feature, userFeatures: PersistedFeatures) => {
  if (feature.requiresOrgAdmin && !currentUser.isOrgAdmin()) return false;
  if (feature.requiresArdoqEmployee && !currentUser.isArdoqEmployee())
    return false;
  return feature.persistedId
    ? Boolean(
        userFeatures[feature.persistedId] ||
          (feature.isEnabledForTrial && currentUser.isTrial()) ||
          (feature.isEnabledForPresentationMode && isPresentationMode())
      )
    : false;
};

export const hasFeature = (feature: Feature): boolean => {
  const userFeatures = getFeatures();

  switch (feature) {
    case Features.GROUPING_RESEARCH:
      return (
        loadedGraph$.state.isViewpointMode ||
        hasFeatureImpl(feature, userFeatures)
      );
    default:
      return hasFeatureImpl(feature, userFeatures);
  }
};

const getFeatures = (): PersistedFeatures => ({
  ...getPersistedUserFeatures(),
  ...getLocalFeatureFlagOverrides(),
});

const getPersistedUserFeatures = (): PersistedFeatures | null => {
  return currentUser.get('features');
};
